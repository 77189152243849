<template>
  <div class="search-what">
    <sport-type
      v-bind="$attrs"
      ref="select2"
      style="width: 100%"
      :css-class="cssClass"
      :initial-value="initialValue"
      :placeholder="placeholder"
      show-all-activities
      @change="handleChange"
    />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SportType from '../common/Form/SportType.vue';

export default {
  name: 'SearchWhat',
  components: { SportType },
  props: {
    cssClass: { type: [String], required: false, default: null },
    initialValue: { type: [String, Number, Array], required: false, default: null },
  },
  emits: ['input'],
  computed: {
    ...mapFields('map', ['sport']),
    placeholder() {
      return this.sport
        ? this.$store.state.sports[this.sport]
        : (this.$attrs.placeholder ?? this.trans('website.search.what'));
    },
  },
  methods: {
    async handleChange(v) {
      // console.log('searchWhat.handleChange', v);
      this.$emit('input', v);
    },
  },
};
</script>
